
import { defineComponent } from "vue";
/* eslint-disable @typescript-eslint/no-explicit-any */
declare var SMap: any;
declare var JAK: any;

interface PointsModel {
  id: number;
  title: string;
  group: string;
  points: PointsPointModel;
  icon: string;
  isExternal: boolean;
  externalLink?: string;
  textContent?: string;
  images?: string[];
  panoId?: number;
  yaw?: number;
  fov?: number;
  pitch?: number;
}

interface PointsPointModel {
  lon: number;
  lat: number;
}

interface PointType {
  title: string;
  type: string;
  isSelected: boolean;
  lon: number;
  lat: number;
  scale: number;
  icon: string;
}

interface SelectedPanoModel {
  panoId: number;
  yaw: number;
  fov: number;
  pitch: number;
}

export default defineComponent({
  name: "Home",
  data(): {
    expandMenu: boolean;
    points: Array<PointsModel>;
    villageCenter: PointsPointModel;
    defaultScale: number;
    showPointsTypes: string[];
    showAll: boolean;
    pointsTypes: Array<PointType>;
    showPopup: boolean;
    popupData: any;
    selectedPano: SelectedPanoModel;
    currentPanoId: number;
    panoIsLoaded: boolean;
  } {
    return {
      expandMenu: false,
      villageCenter: { lon: 16.3906998, lat: 49.8393285 },
      defaultScale: 14,
      showPointsTypes: [],
      showAll: false,
      pointsTypes: [
        {
          title: "Sportovní areály",
          type: "sport",
          isSelected: false,
          lon: 16.3906998,
          lat: 49.8393285,
          scale: 14,
          icon: require("../assets/icons/sport.svg"),
        },
        {
          title: "Dětská hřiště",
          type: "playground",
          isSelected: false,
          lon: 16.3906998,
          lat: 49.8393285,
          scale: 14,
          icon: require("../assets/icons/playground.svg"),
        },
        {
          title: "Podnikatelé",
          type: "business",
          isSelected: false,
          lon: 16.3840533,
          lat: 49.8405083,
          scale: 16,
          icon: require("../assets/icons/business.svg"),
        },
        {
          title: "Obec",
          type: "default",
          isSelected: false,
          lon: 16.3837475,
          lat: 49.840273,
          scale: 17,
          icon: require("../assets/icons/village-house.svg"),
        },
        {
          title: "Památky",
          type: "monuments",
          isSelected: false,
          lon: 16.3906998,
          lat: 49.8393285,
          icon: require("../assets/icons/church.svg"),
          scale: 14,
        },
        {
          title: "Školy/ky",
          isSelected: false,
          type: "school",
          lon: 16.3827229,
          lat: 49.8420997,
          scale: 17,
          icon: require("../assets/icons/school.svg"),
        },
        {
          title: "Autobusové zastávky",
          type: "bus",
          isSelected: false,
          lon: 16.3861507,
          lat: 49.8347615,
          scale: 14,
          icon: require("../assets/icons/bus.svg"),
        },
        {
          title: "Čerpací stanice",
          type: "gas",
          isSelected: false,
          lon: 16.3964075,
          lat: 49.8295435,
          scale: 15,
          icon: require("../assets/icons/gas.svg"),
        },
      ],
      points: [
        {
          id: 1,
          title: "Obecní úřad",
          group: "default",
          points: { lon: 16.3827282, lat: 49.8403881 },
          externalLink: "https://janov-sy.cz/zakladni-informace-1018",
          icon: require("../assets/icons/village-house.svg"),
          isExternal: true,
        },
        {
          id: 2,
          title: "Kostel sv. Filipa a Jakuba",
          group: "monuments",
          points: { lon: 16.3831306, lat: 49.8407825 },
          externalLink: "https://www.janov-sy.cz/kostel-vez-a-zvony-1008",
          icon: require("../assets/icons/church.svg"),
          isExternal: true,
        },
        {
          id: 3,
          title: "Fotbalové hřiště",
          group: "sport",
          points: { lon: 16.3794774, lat: 49.852644 },
          icon: require("../assets/icons/sport.svg"),
          isExternal: false,
          panoId: 59253663,
          yaw: 1.468,
          fov: 1.571,
          pitch: 0.157,
        },
        {
          id: 4,
          title: "Malé fotbalové hřiště",
          group: "sport",
          points: { lon: 16.3789088, lat: 49.8517897 },
          icon: require("../assets/icons/sport.svg"),
          isExternal: false,
          panoId: 59253784,
          yaw: 4.201,
          fov: 1.571,
          pitch: 0.157,
        },
        {
          id: 5,
          title: "Autobusová zastávka - pošta, směr Litomyšl",
          group: "bus",
          points: { lon: 16.3830501, lat: 49.8404158 },
          externalLink: "https://www.seznam.cz/jizdnirady",
          icon: require("../assets/icons/bus.svg"),
          isExternal: true,
        },
        {
          id: 6,
          title: "Autobusová zastávka - pošta, směr Svitavy",
          group: "bus",
          points: { lon: 16.3828623, lat: 49.8403916 },
          externalLink: "https://www.seznam.cz/jizdnirady",
          icon: require("../assets/icons/bus.svg"),
          isExternal: true,
        },
        {
          id: 7,
          title: "Autobusová zastávka - transf., směr Litomyšl",
          group: "bus",
          points: { lon: 16.3763178, lat: 49.8500706 },
          externalLink: "https://www.seznam.cz/jizdnirady",
          icon: require("../assets/icons/bus.svg"),
          isExternal: true,
        },
        {
          id: 8,
          title: "Autobusová zastávka - transf., směr Svitavy",
          group: "bus",
          points: { lon: 16.3761729, lat: 49.8499461 },
          externalLink: "https://www.seznam.cz/jizdnirady",
          icon: require("../assets/icons/bus.svg"),
          isExternal: true,
        },
        {
          id: 9,
          title: "Autobusová zastávka - pila, směr Litomyšl",
          group: "bus",
          points: { lon: 16.3868106, lat: 49.8354233 },
          externalLink: "https://www.seznam.cz/jizdnirady",
          icon: require("../assets/icons/bus.svg"),
          isExternal: true,
        },
        {
          id: 10,
          title: "Autobusová zastávka - pila, směr Svitavy",
          group: "bus",
          points: { lon: 16.3867247, lat: 49.8353057 },
          externalLink: "https://www.seznam.cz/jizdnirady",
          icon: require("../assets/icons/bus.svg"),
          isExternal: true,
        },
        {
          id: 11,
          title: "Autobusová zastávka - Gajer",
          group: "bus",
          points: { lon: 16.4045086, lat: 49.8241044 },
          externalLink: "https://www.seznam.cz/jizdnirady",
          icon: require("../assets/icons/bus.svg"),
          isExternal: true,
        },
        {
          id: 12,
          title: "Čerpací stanice Janov",
          group: "gas",
          points: { lon: 16.3888115, lat: 49.833413 },
          externalLink: "https://mapy.cz/zakladni?source=firm&id=13342438",
          icon: require("../assets/icons/gas.svg"),
          isExternal: true,
        },
        {
          id: 13,
          title: "Čerpací stanice Gajer - BENZINA",
          group: "gas",
          points: { lon: 16.4050714, lat: 49.8234481 },
          externalLink: "https://mapy.cz/zakladni?source=firm&id=12983227",
          icon: require("../assets/icons/gas.svg"),
          isExternal: true,
        },
        {
          id: 14,
          title: "Restaurace Na Rychtě",
          group: "business",
          points: { lon: 16.3834926, lat: 49.8402834 },
          externalLink: "https://www.facebook.com/restauracena.rychte",
          icon: require("../assets/icons/restaurant.svg"),
          isExternal: true,
        },
        {
          id: 15,
          title: "Tělocvična",
          group: "sport",
          points: { lon: 16.382924, lat: 49.841681 },
          externalLink: "https://janov-sy.cz/telocvicna-1014",
          icon: require("../assets/icons/sport.svg"),
          isExternal: true,
        },
        {
          id: 16,
          title: "Víceúčelové hřiště",
          group: "sport",
          points: { lon: 16.3842651, lat: 49.8422553 },
          icon: require("../assets/icons/sport.svg"),
          isExternal: false,
          panoId: 59238700,
          yaw: 1.12,
          fov: 1.571,
          pitch: 0.127,
        },
        {
          id: 17,
          title: "Venkovní hřiště u tělocvičny",
          group: "sport",
          points: { lon: 16.3825036, lat: 49.8415408 },
          icon: require("../assets/icons/sport.svg"),
          textContent:
            "Dříve možnost využití veřejnosti, každoroční soutěž Janovského poháru v nohejbalu.",
          images: [require("../assets/images/telocvicna-hriste/1.jpg")],
          isExternal: false,
        },
        {
          id: 18,
          title: "Fitness park",
          group: "sport",
          points: { lon: 16.3841956, lat: 49.84081 },
          externalLink: "",
          icon: require("../assets/icons/sport.svg"),
          textContent:
            '<p><h3>N&aacute;v&scaron;těvn&iacute; doba</h3></p> <table border="1" cellpadding="1" cellspacing="1"> <tbody> <tr> <td> <p style="text-align:center">Od 15.3. do 15.9.</p> </td> <td> <p style="text-align:center">8:00 až 20:00</p> </td> </tr> <tr> <td> <p style="text-align:center">Od 16.9. do 14.3.</p> </td> <td> <p style="text-align:center">8:00 až 17:00</p> </td> </tr> </tbody> </table> <p>Při cvičen&iacute; postupujte podle instrukc&iacute; dle uveden&yacute;ch na stroj&iacute;ch a dodržujte podm&iacute;nky provozn&iacute;ho ř&aacute;du.</p> <p><em>Fitness park byl vybudov&aacute;n za finančn&iacute; podpory Pardubick&eacute;ho kraje.</em></p>',
          images: [
            require("../assets/images/workout-hriste/1.jpg"),
            require("../assets/images/workout-hriste/2.jpg"),
            require("../assets/images/workout-hriste/3.jpg"),
            require("../assets/images/workout-hriste/4.jpg"),
            require("../assets/images/workout-hriste/5.jpg"),
          ],
          isExternal: false,
          panoId: 59240528,
          yaw: 3.139,
          fov: 0.514,
          pitch: -0.072,
        },
        {
          id: 19,
          title: "Základní škola",
          group: "school",
          points: { lon: 16.3832888, lat: 49.841688 },
          externalLink: "http://www.zsjanov-sy.cz/",
          icon: require("../assets/icons/school.svg"),
          isExternal: true,
        },
        {
          id: 20,
          title: "Mateřská škola",
          group: "school",
          points: { lon: 16.382924, lat: 49.8433866 },
          externalLink: "https://ms-janov.webnode.cz/",
          icon: require("../assets/icons/school.svg"),
          isExternal: true,
        },
        {
          id: 21,
          title: "Potraviny - Večerka",
          group: "business",
          points: { lon: 16.382012, lat: 49.841854 },
          externalLink: "https://www.cba.cz/prodejny-cba/pardubicky-kraj/#Janov",
          icon: require("../assets/icons/store.svg"),
          isExternal: true,
        },
        {
          id: 22,
          title: "Starožitnosti - Antik Janov - Oldřich Tměj",
          group: "business",
          points: { lon: 16.3844368, lat: 49.8411829 },
          externalLink: "https://www.antikjanov.cz/",
          icon: require("../assets/icons/business.svg"),
          isExternal: true,
        },
        {
          id: 23,
          title: "AUTO JANOV",
          group: "business",
          points: { lon: 16.382688, lat: 49.8398717 },
          externalLink: "http://www.autojanov.cz/",
          icon: require("../assets/icons/business.svg"),
          isExternal: true,
        },
        {
          id: 24,
          title: "Dětské hřiště u fotbalového hřiště",
          group: "playground",
          points: { lon: 16.379545, lat: 49.8531628 },
          icon: require("../assets/icons/playground.svg"),
          textContent: "Informace a fotografie v přípravě.",
          isExternal: false,
        },
        {
          id: 25,
          title: "Školkové hřiště",
          group: "playground",
          points: { lon: 16.3834403, lat: 49.8432894 },
          icon: require("../assets/icons/playground.svg"),
          textContent: "Informace v přípravě.",
          images: [require("../assets/images/skolkove-hriste/1.jpg")],
          isExternal: false,
          panoId: 59238921,
          yaw: 1.161,
          fov: 1.257,
          pitch: 0.205,
        },
        {
          id: 26,
          title: "Školní hřiště",
          group: "playground",
          points: { lon: 16.383345, lat: 49.8414469 },
          icon: require("../assets/icons/playground.svg"),
          textContent: "Informace a fotografie v přípravě.",
          images: [],
          isExternal: false,
          panoId: 59254915,
          yaw: 3.767,
          fov: 0.816,
          pitch: 0.094,
        },
        {
          id: 27,
          title: "Dětské hřiště u Restaurace Na Rychtě",
          group: "playground",
          points: { lon: 16.3833081, lat: 49.8401481 },
          icon: require("../assets/icons/playground.svg"),
          textContent: "Informace a fotografie v přípravě.",
          images: [],
          isExternal: false,
          panoId: 59237259,
          yaw: 4.53,
          fov: 1.257,
          pitch: 1.257,
        },
        {
          id: 28,
          title: "Dětské hřiště v obci",
          group: "playground",
          points: { lon: 16.3887853, lat: 49.8354664 },
          icon: require("../assets/icons/playground.svg"),
          textContent: "Informace a fotografie v přípravě.",
          images: [],
          isExternal: false,
          panoId: 59231879,
          yaw: 1.293,
          fov: 1.523,
          pitch: 0.035,
        },
        {
          id: 29,
          title: "Hasičská zbrojnice",
          group: "default",
          points: { lon: 16.3820738, lat: 49.8423037 },
          icon: require("../assets/icons/firefighter.svg"),
          textContent:
            "<p>Sbor dobrovolných hasičů byl v Janově založen 5. 11. 1945. O jeho zrod se zasloužil pan Josef Capoušek.Ten vybídl občany k založení hasičského sboru. Jeho výzva měla okamžitý ohlas. Hasičský sbor v Janově byl založen jako první na okrese Litomyšl v pohraničním pásmu Janova, Byl utvořen asi z 50 občanů obce.</p><p>Členství ve sboru bylo velkou ctí a jeho členové pracovali velmi svědomitě a zodpovědně. Každý člen měl v hasičském sboru svoje nepostradatelné místo a měl svoji výstroj a výzbroj. Jejich hasičská výzbroj se sestávala z dřevěné ruční stříkačky, dřevěné ruční kyvadlové stříkačky a motorové stříkačky.</p><p>V roce 1975 byla vybudována občany obce a členy hasičského sboru pod vedením starosty obce pana Josefa Křenka stávající hasičská zbrojnice.</p>",
          images: [],
          isExternal: false,
          panoId: 59255241,
          yaw: 1.455,
          fov: 0.962,
          pitch: -0.014,
        },
        {
          id: 30,
          title: "Mariánská studánka",
          group: "monuments",
          points: { lon: 16.376385, lat: 49.8580439 },
          icon: require("../assets/icons/water.svg"),
          textContent:
            "<p>Je v dolní části obce u potoka před Strakovem. Postavená předky původního majitele čp. 68 (vesnické jméno „Schanta“, též „Schante“). Ve výklenku soška Marie. Vedle je bývalá studna.</p><br>V roce 2018 byly provedeny restaurátorské práce, které byly provedeny v ateliéru v rámci výuky studenty restaurátorské školy v Litomyšli, kde byly odstraněny veškeré malby a objeveny původní barvy sousoší.",
          images: [
            require("../assets/images/marianska-kaplicka/1.jpg"),
            require("../assets/images/marianska-kaplicka/2.jpg"),
            require("../assets/images/marianska-kaplicka/3.jpg"),
          ],
          isExternal: false,
        },
        {
          id: 31,
          title: "Kříž před hřbitovní zdí na návsi",
          group: "monuments",
          points: { lon: 16.3828914, lat: 49.8408772 },
          icon: require("../assets/icons/cross.svg"),
          textContent:
            "<a href='https://www.janov-sy.cz/kriz-pred-hrbitovni-zdi-na-navsi-2053' target='_blank'>Více informací</a>",
          images: [require("../assets/images/pamatky/kriz-hrbitov.jpg")],
          isExternal: false,
        },
        {
          id: 32,
          title: "Pamětní deska rudoarmějců",
          group: "monuments",
          points: { lon: 16.3838764, lat: 49.8409708 },
          icon: require("../assets/icons/desk.svg"),
          textContent:
            "<a href='https://www.janov-sy.cz/pametni-deska-rudoarmejcu-2054' target='_blank'>Více informací</a>",
          images: [require("../assets/images/pamatky/pametni-deska-rudoarmejcu.jpg")],
          isExternal: false,
        },
        {
          id: 33,
          title: "Mohutný kříž před čp. 25",
          group: "monuments",
          points: { lon: 16.37928, lat: 49.8453922 },
          icon: require("../assets/icons/cross.svg"),
          textContent:
            "<p>Nachází se před čp. 25 u „staré“ silnice do Strakova. Vznik pravděpodobně po r. 1870. Deska s nápisem nedochována.</p>",
          images: [],
          isExternal: false,
        },
        {
          id: 34,
          title: "Kříž v dolní části obce",
          group: "monuments",
          points: { lon: 16.3783883, lat: 49.8533569 },
          icon: require("../assets/icons/cross.svg"),
          textContent:
            "<a href='https://www.janov-sy.cz/kriz-2057' target='_blank'>Více informací</a>",
          images: [],
          isExternal: false,
          panoId: 59253560,
          yaw: 4.823,
          fov: 1.257,
          pitch: 0.066,
        },
        {
          id: 35,
          title: "Pietní artefakt",
          group: "monuments",
          points: { lon: 16.3831561, lat: 49.8408731 },
          icon: require("../assets/icons/desk.svg"),
          textContent:
            "<p>Pietní artefakt na nově zřízeném hřbitově ležel až do června r. 2011 vedle podstavce bývalého kříže za dolní hřbitovní zdí. Byl neutrálního vzhledu, a proto byl vybrán jako základ plánovaného pietního místa na novém hřbitově.</p><br><a href='https://www.janov-sy.cz/pietni-artefakt-2058' target='_blank'>Více informací</a>",
          images: [require("../assets/images/pamatky/pietni-artefakt.jpg")],
          isExternal: false,
        },
        {
          id: 36,
          title: "Mariánský sloup",
          group: "monuments",
          points: { lon: 16.3636575, lat: 49.8508528 },
          icon: require("../assets/icons/cross.svg"),
          textContent:
            "<a href='https://www.janov-sy.cz/mariansky-sloup-2060' target='_blank'>Více informací</a>",
          images: [require("../assets/images/pamatky/mariansky-sloup.jpg")],
          isExternal: false,
          panoId: 59062054,
          yaw: 3.87,
          fov: 1.257,
          pitch: -0.001,
        },
        {
          id: 37,
          title: "Kříž u I/35",
          group: "monuments",
          points: { lon: 16.3604217, lat: 49.8526356 },
          icon: require("../assets/icons/cross.svg"),
          textContent:
            "<a href='https://www.janov-sy.cz/kriz-u-i35-2061' target='_blank'>Více informací</a>",
          images: [require("../assets/images/pamatky/kriz-u-i35.jpg")],
          isExternal: false,
          panoId: 59062628,
          yaw: 3.998,
          fov: 0.933,
          pitch: 0.049,
        },
        {
          id: 38,
          title: "Kříž u dolní hřbitovní zdi",
          group: "monuments",
          points: { lon: 16.38378, lat: 49.8411589 },
          icon: require("../assets/icons/cross.svg"),
          textContent:
            "<a href='https://www.janov-sy.cz/kriz-u-dolni-hrbitovni-zdi-2062' target='_blank'>Více informací</a>",
          images: [require("../assets/images/pamatky/kriz-pod-hrbitovem.jpg")],
          isExternal: false,
          panoId: 59240509,
          yaw: 4.159,
          fov: 1.12,
          pitch: -0.131,
        },
        {
          id: 39,
          title: "Kaple na Gajeru",
          group: "monuments",
          points: { lon: 16.4097361, lat: 49.8217556 },
          icon: require("../assets/icons/church.svg"),
          textContent:
            "<a href='https://www.janov-sy.cz/gajer-1010' target='_blank'>Více informací</a>",
          isExternal: false,
          panoId: 59250652,
          yaw: 4.427,
          fov: 1.571,
          pitch: -0.087,
        },
        {
          id: 40,
          title: "Zámek Mendryka",
          group: "monuments",
          points: { lon: 16.40729, lat: 49.8335783 },
          icon: require("../assets/icons/village-house.svg"),
          textContent:
            "<p>S výstavbou původního renesančního zámečku začal Vratislav z Pernštejna v roce 1573. V průběhu 17. století však zámeček zanikl a pravděpodobně na jeho místě byl v roce 1775 Jiřím Kristiánem z Valdštejna-Vartemberka postaven nový patrový lovecký zámek v barokním duchu. V blízkosti v tu dobu vznikla rokoková kaple sv. Huberta. Později zámecký objekt nechal zmodernizovat řád sester křesťanské lásky sv. Vincenta z Pauly a stal se jejich domovem. Traduje se, že právě budova zámku inspirovala Aloise Jiráska k napsání divadelní hry Lucerna.</p><br><a href='https://janov-sy.cz/mendryka-1009' target='_blank'>Více informací</a>",
          isExternal: false,
          panoId: 59236166,
          yaw: 1.556,
          fov: 1.257,
          pitch: 0.129,
          images: [
            require("../assets/images/pamatky/zamecek-mendryka-1.jpg"),
            require("../assets/images/pamatky/zamecek-mendryka-2.jpg"),
          ],
        },
        {
          id: 41,
          title: "Kaple sv. Huberta",
          group: "monuments",
          points: { lon: 16.407185, lat: 49.8332153 },
          icon: require("../assets/icons/church.svg"),
          textContent:
            "<p>Barokní kaple byla postavena po roce 1775 u nedalekého loveckého zámečku. V současnosti slouží ženskému řeholnímu řádu Společnost Dcer křesťanské lásky sv. Vincence de Paul.</p><br><a href='https://janov-sy.cz/mendryka-1009' target='_blank'>Více informací</a>",
          isExternal: false,
          images: [require("../assets/images/pamatky/kaple-mendryka.jpg")],
        },
      ],
      showPopup: false,
      popupData: {},
      selectedPano: {
        panoId: 0,
        yaw: 0,
        fov: 0,
        pitch: 0,
      },
      currentPanoId: 0,
      panoIsLoaded: false,
    };
  },

  components: {},
  mounted() {
    this.drawMap(this.villageCenter.lon, this.villageCenter.lat, this.defaultScale);
    if (window.location.hash) {
      const parsedHash = parseInt(window.location.hash.replace("#", ""));
      if (parsedHash - 1 >= 0 && parsedHash - 1 <= this.points.length) {
        this.handleClickedPoint(parsedHash);
        const indexCalced = parsedHash - 1;
        const point = this.points[indexCalced];
        this.pointsTypes.forEach((e, index) => {
          if (e.type == point.group) {
            this.showPoints(e.type, index, point.points.lat, point.points.lon, 19);
          }
        });
      }
    } else {
        this.expandAll();
        this.showAll = !this.showAll;
    }
  },
  methods: {
    showPoints: function (
      type: string,
      dataIndex: number,
      lat: number,
      lon: number,
      scale: number
    ) {
      this.pointsTypes[dataIndex].isSelected = !this.pointsTypes[dataIndex].isSelected;

      if (this.showPointsTypes.includes(type)) {
        var index = this.showPointsTypes.indexOf(type);
        if (index !== -1) {
          this.showPointsTypes.splice(index, 1);
        }
        this.drawMap(this.villageCenter.lon, this.villageCenter.lat, this.defaultScale);
      } else {
        this.showPointsTypes.push(type);
        this.drawMap(lon, lat, scale);
      }
    },
    expandAll: function () {
      this.showAll = !this.showAll;
      if (this.showAll) {
        //check if exist in array
        this.pointsTypes.forEach((val) => {
          val.isSelected = true;
          if (!this.showPointsTypes.includes(val.type)) {
            this.showPointsTypes.push(val.type);
          }
        });
      } else {
        this.pointsTypes.forEach((val) => {
          val.isSelected = false;
          this.showPointsTypes.push(val.type);
        });
        this.showPointsTypes = [""];
      }
      this.drawMap(this.villageCenter.lon, this.villageCenter.lat, this.defaultScale);
    },
    drawMap: function (lon: number, lat: number, scale: number) {
      const map = document.querySelector("#m");

      if (map) map.innerHTML = "";
      /*if (map && map.children.length > 2) {
        setTimeout(() => {
          map.innerHTML = "";
        }, 500);
      }*/

      //defaultSetup
      const center = SMap.Coords.fromWGS84(lon, lat);
      var m = new SMap(map, center, scale);
      m.addDefaultLayer(SMap.DEF_BASE).enable();
      m.addDefaultControls();

      /* ADD POINTS */
      this.addPoints(m);
      /* NAPOVIDANI POINTU */
      //this.devPointer(m, center);
    },
    checkSelected: function () {
      if (this.showPointsTypes.length > this.pointsTypes.length) {
        this.showAll = true;
      } else {
        this.showAll = false;
      }
    },
    handleClickedPoint: function (index: number) {
      this.panoIsLoaded = false;
      const popupPoint = this.points[index - 1];
      if (popupPoint.isExternal) {
        window.open(popupPoint.externalLink, "_blank");
      } else {
        this.showPopup = true;
        this.popupData = popupPoint;
        const panoContainer = document.querySelector("#pano");

        this.drawMap(popupPoint.points.lon, popupPoint.points.lat, 19);

        if (popupPoint.panoId && popupPoint.yaw && popupPoint.fov && popupPoint.pitch) {
          this.selectedPano = {
            panoId: popupPoint.panoId,
            yaw: popupPoint.yaw,
            fov: popupPoint.fov,
            pitch: popupPoint.pitch,
          };

          this.currentPanoId = popupPoint.panoId;
          // panorama
          if (popupPoint.panoId > 1 && this.currentPanoId == this.selectedPano.panoId) {
            this.panoIsLoaded = true;
            if (panoContainer) panoContainer.innerHTML = "";
            this.setupPano();
          }
          this.currentPanoId = popupPoint.panoId;
        }
      }
    },

    setupPano: function () {
      setTimeout(() => {
        const panoramaScene = new SMap.Pano.Scene(document.querySelector("#pano"));
        SMap.Pano.get(this.selectedPano.panoId).then(
          (place: any) =>
            panoramaScene.show(place, {
              yaw: this.selectedPano.yaw,
              fov: this.selectedPano.fov,
              pitch: this.selectedPano.pitch,
            }),
          () => {
            alert("Panorama se nepodařilo zobrazit !");
          }
        );
      }, 1);
    },
    devPointer: function (m: any, center: any) {
      var l = new SMap.Layer.Marker();
      m.addLayer(l).enable();

      var mark = new SMap.Marker(center);
      mark.decorate(SMap.Marker.Feature.Draggable);
      l.addMarker(mark);

      function start(e: any) {
        var node = e.target.getContainer();
        node[SMap.LAYER_MARKER].style.cursor = "help";
      }

      function stop(e: any) {
        var node = e.target.getContainer();
        node[SMap.LAYER_MARKER].style.cursor = "";
        var coords = e.target.getCoords();
        alert(coords.x.toString().substr(0, 10) + "---" + coords.y.toString().substr(0, 10));
      }

      var signals = m.getSignals();
      signals.addListener(window, "marker-drag-stop", stop);
      signals.addListener(window, "marker-drag-start", start);
    },
    addPoints: function (m: any) {
      let layer = new SMap.Layer.Marker();

      m.redraw();

      this.points.filter((e, i) => {
        if (this.showPointsTypes.includes(e.group)) {
          const pointImage = JAK.mel(
            "img",
            { src: e.icon },
            { marginTop: "5px", width: "24px", height: "24px", objectFit: "contain" }
          );
          var marker = JAK.mel(
            "div",
            {
              title: e.title,
            },
            {
              position: "absolute",
              left: "0px",
              top: "2px",
              textAlign: "center",
              color: "white",
              fontWeight: "bold",
              background: "url(" + require("../assets/icons/marker.svg") + ")",
              height: "52px",
              backgroundSize: "contain",
              width: "40px",
              backgroundRepeat: "no-repeat",
              transform: "translateY(-1rem)",
              cursor: "pointer",
            }
          );
          marker.appendChild(pointImage);
          if (e.isExternal) {
            marker.dataset.link = e.externalLink;
            //marker.style.cursor = "alias";
          }
          marker.dataset.isExternal = e.isExternal;
          marker.dataset.pointId = e.id;
          marker.classList.add("marker-icon");

          var c = SMap.Coords.fromWGS84(e.points.lon, e.points.lat);

          var pointMarker = new SMap.Marker(c, i, { url: marker });

          layer.addMarker(pointMarker);

          marker.addEventListener("click", (event: any) => {
            if (event.target.nodeName == "IMG") {
              this.handleClickedPoint(
                parseInt(event.target.parentElement.getAttribute("data-point-id"))
              );
              window.location.hash = event.target.parentNode.getAttribute("data-point-id");
            } else {
              this.handleClickedPoint(parseInt(event.target.getAttribute("data-point-id")));
              window.location.hash = event.target.getAttribute("data-point-id");
            }
          });
        }
      });

      m.addLayer(layer);
      layer.enable();
      /* CHECK SELECTED POINTS */
      this.checkSelected();
    },
  },
});
